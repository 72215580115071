// @ts-strict-ignore
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { EQpInputSize, EQpInputBorder } from '@library/components/qp-input/qp-abstract-input.models';
import { qpIsDisabled } from '@library/functions/states/qp-is-disabled';
import { qpIsOptionalTrueStringBoolean } from '@library/functions/states/qp-is-optional-true-string-boolean';
import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { DOCUMENT } from '@angular/common';
import {
  Input,
  ViewChild,
  ChangeDetectorRef,
  Optional,
  Self,
  Inject,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil, has, clone, isEqual } from 'lodash/index';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

/**
 * @description
 * This is a common component between all input components
 */
@Component({
  selector: 'qp-abstract-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export abstract class QpAbstractInputComponent<TValue>
  extends ControlValueAccessor<TValue>
  implements OnInit, AfterContentInit, OnChanges, OnDestroy
{
  public abstract value: QimaOptionalType<TValue>;
  public abstract readonly defaultValue: QimaOptionalType<TValue>;

  /**
   * @description
   * For debug purpose and logs
   * @type {string}
   */
  protected abstract readonly _componentSelector: string;

  /**
   * @type {string}
   * @default ''
   */
  @Input()
  public placeholder: string = '';

  /**
   * @description
   * This is a shortcut to avoid altering the form control disabled state
   * Note that changing this state will not affect the form control and vice-versa
   * Conversion table:
   * undefined      = true
   * null           = true
   * ''             = true
   * true           = true
   * false          = false
   * true (string)  = true
   * false (string) = false
   * Associated to a calculated state {@link QpAbstractInputComponent#isDisabledState}
   * @default false
   */
  @Input()
  public isDisabled: QimaOptionalType<QpStringBooleanType> = false;

  /**
   * @type {QimaOptionalType<EQpInputBorder>}
   * @default EQpInputBorder.FULL
   */
  @Input()
  public border: EQpInputBorder = EQpInputBorder.FULL;

  /**
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input()
  public name: QimaOptionalType<string> = undefined;

  /**
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input()
  public id: QimaOptionalType<string> = undefined;

  /**
   * @description
   * Used to show a green or red UI based on the validators
   * Use this if you have a form control with validation to improve the UX
   * Conversion table:
   * undefined      = true
   * null           = true
   * ''             = true
   * true           = true
   * false          = false
   * true (string)  = true
   * false (string) = false
   * Associated to a calculated state {@link QpAbstractInputComponent#hasValidatorsState}
   * @type {QimaOptionalType<QpStringBooleanType>}
   * @default false
   */
  @Input()
  public hasValidators: QimaOptionalType<QpStringBooleanType> = false;

  /**
   * @description
   * Autofocus the input on initialization
   * Associated to a calculated state {@link QpAbstractInputComponent#isAutofocusedState}
   * @type {QimaOptionalType<QpStringBooleanType>}
   * @default false
   */
  @Input()
  public isAutofocused: QimaOptionalType<QpStringBooleanType> = false;

  /**
   * @description
   * The size of the input
   * @type {EQpInputSize}
   * @default {EQpInputSize.LARGE}
   */
  @Input()
  public size: EQpInputSize = EQpInputSize.LARGE;

  @ViewChild('input')
  public input: QimaOptionalType<ElementRef<HTMLElement>> = undefined;

  public hasFocus: boolean = false;
  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public readonly rootId: string = uuidv4();
  public isDisabledState: boolean = false;
  public isFormControlDisabled: boolean = false;
  public isAutofocusedState: boolean = false;
  public hasValidatorsState: boolean = false;

  /**
   * @description
   * Used for the UI for success/error validation
   * @type {boolean}
   */
  public wasBlurredOnce: boolean = false;

  /**
   * @description
   * Used for the UI for success/error validation
   * @type {boolean}
   */
  public wasFocusedOnce: boolean = false;
  public hasMouseHover: boolean = false;

  /**
   * @description
   * The value to use when resetting the input to an empty state
   * @type {''}
   * @default ''
   * @protected
   */
  protected readonly _emptyInputValue: string = '';
  protected readonly _subscription: Subscription = new Subscription();
  private readonly _valueChangeSubject$: Subject<QimaOptionalType<TValue>> = new Subject<QimaOptionalType<TValue>>();

  public constructor(
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() protected readonly _ngControl: NgControl,
    protected readonly _qpLoggerService: QpLoggerService,
    @Inject(DOCUMENT) protected readonly _document: Document
  ) {
    super();

    if (!isNil(this._ngControl)) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import
      this._ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this._watchValueChange();
  }

  public ngAfterContentInit(): void {
    if (isNil(this._ngControl?.control)) {
      this._qpLoggerService.debug(this._document.getElementById(this.rootId));
      throw new Error(`The ${this._componentSelector} component (id is #${this.rootId}) require an associated form control`);
    } else {
      // If not focused yet, update the focus state on init
      this.wasFocusedOnce = this.wasFocusedOnce ? true : this._ngControl.control.touched;

      // Required to update the state on init
      this._changeDetectorRef.detectChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this._onIsDisabledChange(changes);
    this._onHasValidatorsChange(changes);
    this._onIsAutofocusedChange(changes);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onFocusIn(): void {
    this.hasFocus = true;
    this.onTouched();
  }

  public onFocusOut(): void {
    this.hasFocus = false;
    this.wasBlurredOnce = true;
  }

  public onClick(): void {
    this.focus();
  }

  /**
   * @description
   * Called by the ControlValueAccessor API (from the parent)
   * @template TValue
   * @param {Readonly<TValue>} value The new value
   */
  public writeValue(value: Readonly<TValue>): void {
    this._setValue(value);
  }

  /**
   * @description
   * Called when the input model change
   * @template TValue
   * @param {Readonly<TValue>} value The new value
   */
  public onValueChange(value: Readonly<TValue>): void {
    this._setValue(value);
  }

  public registerOnTouched(fn: () => void): void {
    super.registerOnTouched((): void => {
      this.wasFocusedOnce = true;
      fn();
    });
  }

  public onClearButtonClick(event: Readonly<MouseEvent | Event>): void {
    event.stopPropagation();
    this._setValue(null);
    this.focus();
  }

  public onMouseEnter(): void {
    this.hasMouseHover = true;
  }

  public onMouseLeave(): void {
    this.hasMouseHover = false;
  }

  /**
   * @description
   * Allow to focus the input
   * Useful to expose this from the outside
   */
  public focus(): void {
    this.input?.nativeElement.focus();
    this.onTouched();
  }

  public setDisabledState(isDisabled: Readonly<boolean>): void {
    this.isFormControlDisabled = isDisabled;
    this._setDisabledState(this.isDisabled);
  }

  private _setValue(value: Readonly<TValue>): void {
    this.value = clone(value) ?? this.defaultValue;
    this._valueChangeSubject$.next(this.value);
  }

  private _onIsDisabledChange(changes: SimpleChanges): void {
    if (has(changes, 'isDisabled') && changes.isDisabled.currentValue !== changes.isDisabled.previousValue) {
      this._setDisabledState(changes.isDisabled.currentValue);
    }
  }

  private _onHasValidatorsChange(changes: SimpleChanges): void {
    if (has(changes, 'hasValidators') && changes.hasValidators.currentValue !== changes.hasValidators.previousValue) {
      this.hasValidatorsState = qpIsOptionalTrueStringBoolean(changes.hasValidators.currentValue);
      this._changeDetectorRef.detectChanges();
    }
  }

  private _onIsAutofocusedChange(changes: SimpleChanges): void {
    if (has(changes, 'isAutofocused') && changes.isAutofocused.currentValue !== changes.isAutofocused.previousValue) {
      this.isAutofocusedState = qpIsOptionalTrueStringBoolean(changes.isAutofocused.currentValue);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * @description
   * Define the disabled state based on the form control disabled state
   * As well as the custom one coming from the [disabled input]{@link QpAbstractInputComponent#isDisabled}
   * @param {Readonly<QpStringBooleanType>} isDisabled The disabled input
   * @private
   */
  private _setDisabledState(isDisabled: Readonly<QimaOptionalType<QpStringBooleanType>>): void {
    const shouldBeDisabled: boolean = qpIsDisabled(isDisabled) || this.isFormControlDisabled;

    if (this.isDisabledState !== shouldBeDisabled) {
      this.isDisabledState = shouldBeDisabled;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * @description
   * Listen to value changes
   * The only purpose of having this subject is to avoid multiple value change
   * When the stream value is exactly the same
   * @private
   */
  private _watchValueChange(): void {
    this._subscription.add(
      this._valueChangeSubject$
        .pipe(
          distinctUntilChanged((oldValue: Readonly<QimaOptionalType<TValue>>, newValue: Readonly<QimaOptionalType<TValue>>): boolean =>
            isEqual(oldValue, newValue)
          )
        )
        .subscribe({
          next: (value: QimaOptionalType<TValue>): void => {
            this.onChange(value);
            this._changeDetectorRef.detectChanges();
          },
        })
    );
  }
}
